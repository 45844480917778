import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';

const Item = styled(Paper)(({theme}) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function NestedGrid() {
  return (
    <Box sx={{flexGrow: 1}}>
      <Grid container spacing={2}>
        <Grid xs={12} md={5} lg={4}>
          <Item>Email subscribe section</Item>
        </Grid>
        <Grid container xs={12} md={7} lg={8} spacing={4}>
          <Grid xs={6} lg={3}>
            <Item>
              <Box
                id='category-a'
                sx={{fontSize: '12px', textTransform: 'uppercase'}}
              >
                Category A
              </Box>
              <Box component='ul' aria-labelledby='category-a' sx={{pl: 2}}>
                <li>Link 1.1</li>
                <li>Link 1.2</li>
                <li>Link 1.3</li>
              </Box>
            </Item>
          </Grid>
          <Grid xs={6} lg={3}>
            <Item>
              <Box
                id='category-b'
                sx={{fontSize: '12px', textTransform: 'uppercase'}}
              >
                Category B
              </Box>
              <Box component='ul' aria-labelledby='category-b' sx={{pl: 2}}>
                <li>Link 2.1</li>
                <li>Link 2.2</li>
                <li>Link 2.3</li>
              </Box>
            </Item>
          </Grid>
          <Grid xs={6} lg={3}>
            <Item>
              <Box
                id='category-c'
                sx={{fontSize: '12px', textTransform: 'uppercase'}}
              >
                Category C
              </Box>
              <Box component='ul' aria-labelledby='category-c' sx={{pl: 2}}>
                <li>Link 3.1</li>
                <li>Link 3.2</li>
                <li>Link 3.3</li>
              </Box>
            </Item>
          </Grid>
          <Grid xs={6} lg={3}>
            <Item>
              <Box
                id='category-d'
                sx={{fontSize: '12px', textTransform: 'uppercase'}}
              >
                Category D
              </Box>
              <Box component='ul' aria-labelledby='category-d' sx={{pl: 2}}>
                <li>Link 4.1</li>
                <li>Link 4.2</li>
                <li>Link 4.3</li>
              </Box>
            </Item>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          container
          justifyContent='space-between'
          alignItems='center'
          flexDirection={{xs: 'column', sm: 'row'}}
          sx={{fontSize: '12px'}}
        >
          <Grid sx={{order: {xs: 2, sm: 1}}}>
            <Item>© Copyright</Item>
          </Grid>
          <Grid container columnSpacing={1} sx={{order: {xs: 1, sm: 2}}}>
            <Grid>
              <Item>Link A</Item>
            </Grid>
            <Grid>
              <Item>Link B</Item>
            </Grid>
            <Grid>
              <Item>Link C</Item>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
