import * as React from 'react';
import Radio from '@mui/material/Radio';

export default function RadioButtons() {
  const [selectedValue, setSelectedValue] = React.useState('a');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <div>
      <Radio
        checked={selectedValue === 'a'}
        onChange={handleChange}
        value='a'
        name='radio-buttons'
        inputProps={{'aria-label': 'A'}}
      />
      <Radio
        checked={selectedValue === 'b'}
        onChange={handleChange}
        value='b'
        name='radio-buttons'
        inputProps={{'aria-label': 'B'}}
      />
    </div>
  );
}
